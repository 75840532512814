import classnames from "classnames";
import * as React from "react";
import { Alert } from "../Alert";
import { useAppConfigContext } from "../AppConfig";
import Button, { buttonClassName } from "../Button";
import Icon from "../Icon";
import { ModalWindow } from "../ModalWindow";
import { insightFetch } from "../insightFetch";
import styles from "./HelpModal.css";

type Organisation = {
  id: string;
  name: string;
};

type HelpModalProps = {
  helpUrl: string;
  organisations?: Organisation[];
  onClose: VoidFunction;
  isUserflowBlocked: boolean;
};

export function HelpModal({
  helpUrl,
  organisations,
  onClose,
  isUserflowBlocked,
}: HelpModalProps) {
  const appConfigContext = useAppConfigContext();
  const ambientOrganisation = appConfigContext?.organisation;
  if (ambientOrganisation) {
    organisations = [
      {
        id: `${ambientOrganisation.type}s/${ambientOrganisation.shortId}`,
        name: ambientOrganisation.name,
      },
    ];
  } else if (!organisations || organisations.length === 0) {
    throw new Error("organisations array is missing or empty.");
  }

  return (
    <ModalWindow onClose={onClose} style={{ width: "90%", maxWidth: 900 }}>
      <div className={styles.wrapper}>
        {isUserflowBlocked && (
          <Alert type="warning">
            You are not getting the optimum support experience, likely due to
            settings on your network. Share{" "}
            <a
              href="https://kb.insighttracking.com/article/s5ynuur133-network-requirements"
              target="_blank"
              rel="noreferrer"
            >
              this guide
            </a>{" "}
            with your network management to resolve.
          </Alert>
        )}
        <EmailSupportSection helpUrl={helpUrl} organisations={organisations} />
        {isUserflowBlocked && <HelpGuidesSection />}
        <TelephoneSection />
      </div>
    </ModalWindow>
  );
}

function EmailSupportSection({
  helpUrl,
  organisations,
}: {
  helpUrl: string;
  organisations: Organisation[];
}) {
  const [files, setFiles] = React.useState([]);
  const [status, setStatus] = React.useState<"idle" | "busy" | "success">(
    "idle"
  );

  function handleFileSelected(
    changeEvent: React.ChangeEvent<HTMLInputElement>
  ) {
    const fileInput = changeEvent.target;
    const filesWithNew = files.concat(Array.from(fileInput.files));
    const totalSize = filesWithNew.map(f => f.size).reduce((x, y) => x + y, 0);
    const fileSizeLimit = 10 * 1024 * 1024; // 10 MB
    if (totalSize > fileSizeLimit) {
      alert(
        "Files must be less than 10MB. You may want to try zipping them first."
      );
    } else {
      setFiles(filesWithNew);
    }
    fileInput.value = null; // Clears selected files
  }

  function removeFile(file) {
    setFiles(files.filter(f => f !== file));
  }

  function send(submitEvent: React.FormEvent) {
    submitEvent.preventDefault();

    setStatus("busy");

    const formData = new FormData(submitEvent.target as HTMLFormElement);
    formData.append("browser", navigator.userAgent);
    formData.append("pageUrl", location.toString());
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    return insightFetch(helpUrl, {
      method: "POST",
      body: formData,
    })
      .then(r => {
        return r.ok ? null : Promise.reject(r);
      })
      .then(
        () => setStatus("success"),
        () => {
          setStatus("idle");
          alert("Unable to send message. Please try again.");
        }
      );
  }
  return (
    <div className={styles.email}>
      <form onSubmit={send}>
        <h2>
          <Icon className={styles.icon} icon="envelope" /> Email Support
        </h2>

        <p>
          Email{" "}
          <a href="mailto:support@insighttracking.com">
            support@insighttracking.com
          </a>{" "}
          or enter a message below. <br />
          We aim to get back to you within 1 working day.
        </p>

        {status === "success" ? (
          <p className={classnames(styles.success, "alert-success")}>
            Thanks, we&apos;ll be in touch soon.
          </p>
        ) : (
          <>
            {organisations.length === 1 && (
              <input
                type="hidden"
                name="organisationId"
                value={organisations[0].id}
              />
            )}

            {organisations.length > 1 && (
              <>
                <label className={styles.label} htmlFor="help-organisation-id">
                  Regarding School or MAT
                </label>
                <select
                  id="help-organisation-id"
                  name="organisationId"
                  className="input-block-level"
                  required
                >
                  {organisations.map(org => (
                    <option key={org.id} value={org.id}>
                      {org.name}
                    </option>
                  ))}
                </select>
              </>
            )}

            <label className={styles.label} htmlFor="email-message">
              Message
            </label>

            <textarea
              name="message"
              autoFocus
              rows={10}
              className="input-block-level"
              id="email-message"
              required
            />

            {files.map((file, index) => (
              <p key={index}>
                <Icon icon="paperclip" /> {file.name}{" "}
                <Icon
                  icon="trash-alt"
                  iconStyle="far"
                  onClick={() => removeFile(file)}
                  style={{ cursor: "pointer" }}
                  title="Remove file"
                />
              </p>
            ))}

            <div className={styles.formButtons}>
              <Button
                type="submit"
                size="medium"
                variant="primary"
                className={classnames(styles.submit)}
                busy={status === "busy"}
              >
                Send Message
              </Button>

              <label
                className={classnames(
                  styles.file,
                  buttonClassName(undefined, "medium")
                )}
                htmlFor="email-file-upload"
              >
                Attach File
              </label>

              <input
                type="file"
                id="email-file-upload"
                onChange={handleFileSelected}
              />

              <p className={styles.small}>
                You can send us files securely (up to 10MB).
              </p>
            </div>
          </>
        )}
      </form>
    </div>
  );
}

function HelpGuidesSection() {
  return (
    <div className={styles.help}>
      <h2>
        <Icon className={styles.icon} icon="life-ring" /> Help Guides
      </h2>

      <a
        href="https://kb.insighttracking.com/"
        target="_blank"
        rel="noreferrer"
      >
        View Help Guides »
      </a>
    </div>
  );
}

function TelephoneSection() {
  return (
    <>
      <h2>
        <Icon className={styles.icon} icon="phone" /> Telephone Support
      </h2>

      <p className={styles.call}>
        Call <span>020 3393 4005</span>
      </p>

      <p>We offer technical support by phone, Monday to Friday, 9am - 4pm.</p>
    </>
  );
}

export type HelpModalController = {
  readonly isOpen: boolean;
  readonly isUserflowBlocked: boolean;
  readonly organisations: Organisation[];
  setOrgs(organisations: Organisation[]): void;
  open(userflowBlocked?: boolean): void;
  close(): void;
};

export const HelpModalContext = React.createContext<HelpModalController>(null);

declare global {
  interface Window {
    openHelpModal: VoidFunction;
  }
}

export function useHelpModalController(): HelpModalController {
  const [open, setOpen] = React.useState(false);
  const [userflowBlocked, setUserflowBlocked] = React.useState(false);
  const [orgs, setOrgs] = React.useState<Organisation[]>([]);

  React.useEffect(() => {
    window.openHelpModal = () => setOpen(true);
  }, []);

  return {
    isOpen: open,
    isUserflowBlocked: userflowBlocked,
    organisations: orgs,
    setOrgs(organisations: Organisation[]) {
      setOrgs(organisations);
    },
    open(userflowBlocked = false) {
      if (userflowBlocked) {
        setUserflowBlocked(true);
      }
      setOpen(true);
    },
    close() {
      setUserflowBlocked(false);
      setOpen(false);
    },
  };
}
