import * as React from "react";
import { CurrentUserDto } from "@server-types/users";
import { useAppConfigContext } from "./AppConfig";
import { insightFetch } from "./insightFetch";

export const CurrentUserContext = React.createContext<CurrentUserDto | null>(
  null
);

export function CurrentUserProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const config = useAppConfigContext();
  const [user, setUser] = React.useState<CurrentUserDto>(null);

  React.useEffect(() => {
    async function getMe(organisationId: string) {
      const response = await insightFetch(
        `/me?organisationId=${organisationId}`
      );
      if (response.ok) {
        setUser((await response.json()) as CurrentUserDto);
      } else {
        setUser(null);
      }
    }

    let organisationId: string;
    if (config.organisation.type === "Trust") {
      organisationId = `trusts/${config.organisation.shortId}`;
    } else if (config.organisation.type === "School") {
      organisationId = `schools/${config.organisation.shortId}`;
    } else {
      throw new Error(
        "Unexpected type of organisation: " + config.organisation.type
      );
    }

    getMe(organisationId);
  }, [config.organisation.shortId, config.organisation.type]);

  return (
    <CurrentUserContext.Provider value={user}>
      {children}
    </CurrentUserContext.Provider>
  );
}

export function useCurrentUser(): CurrentUserDto | null {
  return React.useContext(CurrentUserContext);
}
