import React from "react";
import ReactDOM from "react-dom";
import { LoginPageData } from "@server-types/public";
import { EmptyAppFrame } from "../shared/AppFrame";
import { App } from "./App";

export declare const pageData: LoginPageData;

ReactDOM.render(
  <EmptyAppFrame>
    <App pageData={pageData} />
  </EmptyAppFrame>,
  document.getElementById("app")
);
