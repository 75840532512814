export const pupilQueryStorage = {
  init(currentAcademicYear) {
    this.currentAcademicYear = currentAcademicYear;
    this.removeOldItems();
  },
  /**
   * Includes academic year in key because when starting a new year,
   * we want the filters to reset.
   */
  pupilQueryKey() {
    return `pupilQuery${this.currentAcademicYear}`;
  },
  blankPupilQuery() {
    return {
      academicYear: this.currentAcademicYear,
      filters: {},
      groupers: [],
    };
  },
  /** Remove any pupil queries stored for past years. */
  removeOldItems() {
    const currentKey = this.pupilQueryKey();
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key !== currentKey && key.match(/^pupilQuery\d{4}$/)) {
        localStorage.removeItem(key);
      }
    }
  },
  set(pupilQuery) {
    try {
      localStorage.setItem(this.pupilQueryKey(), JSON.stringify(pupilQuery));
    } catch {
      // Ignore error
    }
  },
  get() {
    try {
      const json = localStorage.getItem(this.pupilQueryKey());
      if (json) {
        return JSON.parse(json);
      } else {
        return this.blankPupilQuery();
      }
    } catch {
      return this.blankPupilQuery();
    }
  },
  exists() {
    try {
      return Boolean(localStorage.getItem(this.pupilQueryKey()));
    } catch {
      return false;
    }
  },
};
