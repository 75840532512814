export default function yearGroupName(y: number | null): string {
  if (y === null) return "";

  if (y > 0) {
    return "Year " + y;
  } else if (y === 0) {
    return "Reception";
  } else if (y === -1) {
    return "Nursery 2";
  } else if (y === -2) {
    return "Nursery 1";
  } else if (y === -3) {
    return "Early 2";
  } else if (y === -4) {
    return "Early 1";
  } else {
    return "Year " + y;
  }
}

export function shortYearGroupName(y: number | null): string {
  if (y === null) return "";

  if (y > 0) {
    return "Y" + y;
  } else if (y === 0) {
    return "YR";
  } else if (y === -1) {
    return "N2";
  } else if (y === -2) {
    return "N1";
  } else if (y === -3) {
    return "E2";
  } else if (y === -4) {
    return "E1";
  } else {
    return "Y" + y;
  }
}
