import classNames from "classnames";
import React from "react";
import ReactDOM from "react-dom";
import styles from "./ModalWindow.css";

export function ModalWindow({
  title,
  children,
  onClose,
  style,
  fullScreen,
}: {
  title?: string | React.ReactNode;
  children: React.ReactNode;
  onClose?: VoidFunction;
  style?: React.CSSProperties;
  fullScreen?: boolean;
}) {
  // Hide page scroll bar while modal is visible.
  React.useEffect(() => {
    const originalValue = document.body.style.overflow;
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = originalValue;
    };
  }, []);

  return ReactDOM.createPortal(
    <div className={styles.modalBackdrop}>
      <div
        className={classNames(styles.modal, {
          [styles.fullScreen]: fullScreen,
        })}
        onClick={e => e.stopPropagation()}
        style={style}
      >
        {title && <h4 className={styles.title}>{title}</h4>}
        {onClose && (
          <button className={styles.close} title="Close" onClick={onClose}>
            &times;
          </button>
        )}
        {children}
      </div>
    </div>,
    document.body
  );
}

export function ModalWindowBody({
  children,
  style,
}: {
  children: React.ReactNode;
  style?: React.CSSProperties;
}) {
  return (
    <div className={styles.body} style={style}>
      {children}
    </div>
  );
}

export function ModalWindowFooter({
  children,
  style,
}: {
  children: React.ReactNode;
  style?: React.CSSProperties;
}) {
  return (
    <div className={styles.footer} style={style}>
      {children}
    </div>
  );
}
