import React, { ReactNode, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

type TetherProps = {
  to: Element;
  align: "left" | "right";
  children: React.ReactNode;
};

function Tether({ to, align, children }: TetherProps) {
  const rect = to.getBoundingClientRect();
  const pos =
    align === "right"
      ? {
          right: 10,
        }
      : {
          left: rect.left,
        };
  return ReactDOM.createPortal(
    // High z-index to beat Userflow
    <div
      style={{ position: "fixed", zIndex: 1234601, top: rect.bottom, ...pos }}
    >
      {children}
    </div>,
    document.body
  );
}

export function useDropdownMenu(
  createMenu: () => ReactNode,
  align: "left" | "right"
) {
  const [visible, setVisible] = useState(false);
  const buttonRef = useRef<Element>(null);

  useEffect(() => {
    if (!createMenu) return;

    function handleDocumentClick(e: MouseEvent) {
      if (e.target === buttonRef.current) {
        return;
      }

      if (visible) {
        setVisible(false);
        buttonRef.current = null;
      }
    }
    document.addEventListener("click", handleDocumentClick, false);
    // Clean-up: remove document click event handler
    return () => {
      document.removeEventListener("click", handleDocumentClick, false);
    };
  }, [createMenu, visible]);

  function handleButtonClick(
    clickEvent: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    if (visible) {
      setVisible(false);
      buttonRef.current = null;
    } else {
      setVisible(true);
      buttonRef.current = clickEvent.currentTarget;
    }
  }

  return {
    handleButtonClick,
    menu: visible ? (
      <Tether to={buttonRef.current} align={align}>
        {createMenu()}
      </Tether>
    ) : null,
  };
}
