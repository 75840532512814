import * as React from "react";
import { Alert } from "@/shared/Alert";

export default function OutOfDateBrowser() {
  return (
    <div style={{ width: "360px", margin: "0 auto 30px" }}>
      <Alert type="warning">
        <h4>Please note</h4>
        <div>
          You are using an older web browser, so unfortunately some parts of
          Insight may not look as we intended. If possible, please update your
          device to the latest version of iOS.
        </div>
      </Alert>
    </div>
  );
}
