import { useEffect } from "react";

export type DestinationOrganisation = {
  type: "school" | "trust";
  shortId: string;
  parent: DestinationOrganisation | null;
};

/** Returns a specific page URL for the given organisation.
 * Or returns null if a specific page isn't available. */
type GetOrganisationPageUrl = (
  organisation: DestinationOrganisation
) => string | null;

// By default, we don't have any specific page for an organisation.
const defaultGetOrganisationPageUrl: GetOrganisationPageUrl = () => null;

let currentGetOrganisationPageUrl = defaultGetOrganisationPageUrl;

export function useRegisterOrganisationPageUrlFunction(
  getOrganisationPageUrl: GetOrganisationPageUrl
) {
  useEffect(() => {
    currentGetOrganisationPageUrl = getOrganisationPageUrl;

    // Reset to default when unmounting.
    return () => {
      currentGetOrganisationPageUrl = defaultGetOrganisationPageUrl;
    };
  }, [getOrganisationPageUrl]);
}

export function getOrganisationPageUrl(
  organisation: DestinationOrganisation,
  defaultUrl: string
): string {
  return currentGetOrganisationPageUrl(organisation) ?? defaultUrl;
}
