import React from "react";
import { useLocation } from "react-router-dom";
import styles from "./Alert.css";

export type AlertType = "error" | "warning" | "info" | "success";

type AlertProps = {
  type: AlertType;
  children: React.ReactNode;
};

export type AlertState = {
  type: AlertType;
  text: string;
};

export function Alert({ type, children }: AlertProps) {
  return <div className={styles[type]}>{children}</div>;
}

export function AlertFromHistoryState() {
  const [show, setShow] = React.useState(false);
  const location = useLocation();
  const data = location.state?.alert;

  React.useEffect(() => {
    if (!data?.type || !data?.text) {
      return;
    }

    setShow(true);

    // Clear state here to prevent message showing again on refresh
    history.replaceState(null, "");

    if (data?.type !== "error") {
      const timeout = setTimeout(() => setShow(false), 5000);
      return () => clearTimeout(timeout);
    }
  }, [data?.text, data?.type]);

  if (!data?.text || !show) {
    return null;
  }

  return <Alert type={data.type}>{data.text}</Alert>;
}
