import yearGroupName, { shortYearGroupName } from "./yearGroupName";

export type YearGroupInfo = {
  number: number;
  name: string;
  shortName: string;
};

const allYearGroups: YearGroupInfo[] = [
  -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,
].map(y => ({
  number: y,
  name: yearGroupName(y),
  shortName: shortYearGroupName(y),
}));

export default allYearGroups;
