import React from "react";
import Button from "@/shared/Button";
import Icon from "@/shared/Icon";

export function PageHiddenForPrivateMode() {
  function switchOffPrivateMode() {
    document.cookie = `private-mode=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
    location.reload();
  }

  return (
    <div style={{ padding: "0 20px" }}>
      <h1>
        <Icon icon="eye-slash" /> Page Hidden
      </h1>
      <p>
        This page contains pupil details, so has been hidden because private
        mode is switched on.
      </p>
      <p>Switch off private mode to view the page.</p>
      <Button onClick={switchOffPrivateMode}>Switch Off Private Mode</Button>
    </div>
  );
}
